body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
		'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.empty__text {
	color: #016193;
	font-weight: bold;
}

.icon-hover-rotate {
	transform: rotate(0deg);
	transition: transform 1000ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	transform-origin: center center;
	display: inline-block;
}

.icon-hover-rotate.icon-hover-rotate--minimized {
	transform: rotate(90deg);
}

.icon-hover-rotate-container:hover .icon-hover-rotate {
	transform: rotate(990deg);
}

.icon-hover-rotate-container:hover .icon-hover-rotate--minimized {
	transform: rotate(900deg);
}
